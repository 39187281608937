import {handleRequest} from "./API";
import {
  BookingListItemTypes,
  CreateBookingDataTypes,
  CreateBookingDetailsDataTypes, CreateBookingResTypes, SsrCodeTypes
} from "../types/Booking/Booking.types";

const BOOKING_API = {
  getBookings: async (token: string) => {
    return await handleRequest<BookingListItemTypes[]>(
      "get",
      `bookings`,
      token
    );
  },
  createBooking: async (token: string, bookingData: CreateBookingDataTypes) => {
    return await handleRequest<CreateBookingResTypes>(
      "post",
      `bookings`,
      token,
      bookingData
    );
  },
  createBookingDetails: async (token: string, bookingId: number, bookingDetailsData: CreateBookingDetailsDataTypes) => {
    return await handleRequest(
      "post",
      `bookings/${bookingId}/details`,
      token,
      bookingDetailsData
    );
  },
  deleteBooking: async (token: string, bookingId: number) => {
    return await handleRequest(
      "delete",
      `bookings/${bookingId}`,
      token
    );
  },
  getSsrCodes: async (token: string) => {
    return await handleRequest<SsrCodeTypes[]>(
      "get",
      `ssr/codes`,
      token
    );
  }
}

export default BOOKING_API;
