import {createSlice} from '@reduxjs/toolkit';
import {createBooking, getBookingList, getSsrCodesList} from "../thunk/bookingsThunks";

const initialState = {
  isLoading: false,
  isCreating: false,
  isSsrLoading: false,
  bookings: [],
  ssrCodes: [],
  error: null,
}

const bookingsSlice = createSlice({
  name: 'bookings',
  initialState,
  reducers: {
    resetBookings: (state) => {
      state.bookings = initialState.bookings;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBookingList.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getBookingList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.bookings = action.payload?.length ? action.payload : initialState.bookings;
      })
      .addCase(getBookingList.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });

    builder
      .addCase(getSsrCodesList.pending, (state) => {
        state.isSsrLoading = true;
        state.error = null;
      })
      .addCase(getSsrCodesList.fulfilled, (state, action) => {
        state.isSsrLoading = false;
        state.ssrCodes = action.payload?.length ? action.payload : initialState.ssrCodes;
      })
      .addCase(getSsrCodesList.rejected, (state, action) => {
        state.isSsrLoading = false;
        state.error = action.payload;
      });

    builder
      .addCase(createBooking.pending, (state) => {
        state.isCreating = true;
        state.error = null;
      })
      .addCase(createBooking.fulfilled, (state, action) => {
        state.isCreating = false;
      })
      .addCase(createBooking.rejected, (state, action) => {
        state.isCreating = false;
        state.error = action.payload;
      });
  },
});

export const { resetBookings } = bookingsSlice.actions;

export default bookingsSlice.reducer;
